import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/home/">Strona główna</a>
                </li>
                <li>
                  <a href="/produkty/">Produkty</a>
                </li>
                <li>
                  <a href="/poradnik/">Poradnik</a>
                </li>
                <li>
                  <a className="sel" href="/dobierz-lek/">
                    Test
                  </a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/pytania-i-odpowiedzi/">Pytania i odpowiedzi</a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Strona główna</a>
            <a href="/dobierz-lek/" className="sel">
              {" "}
              &gt; Test
            </a>
          </div>
          <div id="quiz_content" className="rel">
           <div className="quiz_images">
            <span
              className="quiz_man"
              style={{
                backgroundImage:
                  'url("/kcfinder/uploads/files/quiz/familykopia.png")',
              }}
            />
            <span
              className="quiz_man_result"
              style={{
                backgroundImage:
                  'url("/kcfinder/uploads/files/quiz/Sudafedfather.png")',
              }}
            />
            <span
                className="quiz_woman"
                style={{
                  backgroundImage:
                    'url("/kcfinder/uploads/files/quiz/Sudafedmother.png")',
                }}
              />
            <span
                className="quiz_girl"
                style={{
                  backgroundImage:
                    'url("/kcfinder/uploads/files/quiz/Sudafedgirl.png")',
                }}
              />
              <span
                className="quiz_boy"
                style={{
                  backgroundImage:
                    'url("/kcfinder/uploads/files/quiz/Sudafedboy.png")',
                }}
              />
            </div>
            <div id="quiz">
              <div className="window">
                <div className="quiz_start active">
                  <div className="content">
                    <p>
                      ODPOWIEDZ NA PARĘ PYTAŃ I ZNAJDŹ NAJBARDZIEJ ODPOWIEDNI
                      PRODUKT DLA SIEBIE
                    </p>
                  </div>
                  <span className="btn_start">Rozpocznij test</span>
                </div>
                <div
                  className="question q_1 first"
                  data-prev=""
                  data-products=".product_1,.product_2,.product_3,.product_4,.product_5,.product_6,.product_7,.product_8,.product_9"
                >
                  <div className="content">
                    <p>
                      Szukasz pomocy w walce z objawami przeziębienia, grypy
                      lub&nbsp;kataru alergicznego dla siebie czy swojego
                      dziecka?
                    </p>
                  </div>
                  <div className="nawig">
                    <span
                      className="answer "
                      data-aid="1"
                      data-qid="1"
                      data-next="2"
                    >
                      Dla dziecka
                    </span>
                    <span
                      className="answer "
                      data-aid="2"
                      data-qid="1"
                      data-next="3"
                    >
                      Dla osoby dorosłej
                    </span>
                  </div>
                  <div
                    className="clear note ac"
                    style={{
                      "padding-top": "20px",
                    }}
                  ></div>
                  <span className="back answerback">COFNIJ</span>
                </div>
                <div
                  className="question q_2 "
                  data-prev=""
                  data-products=".product_3,.product_4,.product_7,.product_9"
                >
                  <div className="content">
                    <p>Jakie objawy zauważyłaś u swojego dziecka?</p>
                  </div>
                  <div className="nawig">
                    <span
                      className="answer "
                      data-aid="3"
                      data-qid="2"
                      data-next="7"
                    >
                      <p>Katar</p>
                    </span>
                    <span
                      className="answer "
                      data-aid="4"
                      data-qid="2"
                      data-next=""
                    >
                      <p>Katar i kaszel</p>
                    </span>
                    <div className="resume resume_4">
                      <p className="name">Wynik</p>
                      <div className="wysiwyg">
                        <p className="p1">
                          <span className="s1">
                            Lek w formie syropu o przyjemnym jeżynowym smaku.
                            Działa potrójnie: odblokowuje nos, zmniejsza ilość
                            wydzieliny oraz łagodzi suchy kaszel.
                          </span>
                        </p>
                        <p>
                          <br />
                          <img
                            src="/web/kcfinder/uploads/images/actifed.png"
                            style={{
                              width: "177px",
                              height: "239px",
                            }}
                          />
                          <br />
                          <span
                            style={{
                              color: "#cb0073",
                              "font-weight": "900",
                            }}
                          >
                            ACTIFED<sup>®</sup> SYROP
                            <br />
                            NA KATAR I KASZEL{" "}
                          </span>
                          <br />
                          <br />
                          <a
                            className="more"
                            href="/produkty/actifed_syrop_na_katar_i_kaszel/"
                          >
                            WIĘCEJ
                          </a>
                        </p>
                      </div>
                      <span className="back resumeback">COFNIJ</span>
                    </div>
                  </div>
                  <div
                    className="clear note ac"
                    style={{
                      "padding-top": "20px",
                    }}
                  ></div>
                  <span className="back answerback">COFNIJ</span>
                </div>
                <div
                  className="question q_3 "
                  data-prev=""
                  data-products=".product_1,.product_2,.product_4,.product_5,.product_6,.product_8"
                >
                  <div className="content">
                    <p>
                      Czy jedynym objawem, który zauważyłeś u siebie jest katar?
                    </p>
                  </div>
                  <div className="nawig">
                    <span
                      className="answer "
                      data-aid="5"
                      data-qid="3"
                      data-next="4"
                    >
                      <p>Tak</p>
                    </span>
                    <span
                      className="answer "
                      data-aid="6"
                      data-qid="3"
                      data-next="5"
                    >
                      <p>Nie</p>
                    </span>
                  </div>
                  <div
                    className="clear note ac"
                    style={{
                      "padding-top": "20px",
                    }}
                  ></div>
                  <span className="back answerback">COFNIJ</span>
                </div>
                <div
                  className="question q_4 "
                  data-prev=""
                  data-products=".product_1,.product_2,.product_5,.product_8"
                >
                  <div className="content">Jakie rozwiązanie preferujesz? </div>
                  <div className="nawig">
                    <span
                      className="answer "
                      data-aid="7"
                      data-qid="4"
                      data-next=""
                    >
                      <p>Wolę dyskretną formę stosowania</p>
                    </span>
                    <div className="resume resume_7">
                      <p className="name">Wynik</p>
                      <div className="wysiwyg">
                        <p className="p1">
                          <span className="s1">
                            Lek w formie tabletek, odblokowuje nos i zatoki
                            ułatwiając oddychanie. Zmniejsza ilość zalegającej w
                            nosie i zatokach wydzieliny oraz ułatwia jej
                            usunięcie eliminując uczucie ucisku w głowie i
                            zatokach<sup>1</sup>.
                          </span>
                        </p>
                        <p>
                          <br />
                          <img
                            src="/web/kcfinder/uploads/images/tabletki_02.png"
                            style={{
                              width: "200px",
                              height: "168px",
                            }}
                          />
                          <br />
                          <span
                            style={{
                              color: "#171b73",
                              "font-weight": "900",
                            }}
                          >
                            SUDAFED<sup>®</sup> TABLETKI
                          </span>
                          <br />
                          <br />
                          <a
                            className="more"
                            href="/produkty/sudafed_tabletki/"
                          >
                            WIĘCEJ
                          </a>
                        </p>
                        <div
                          className="clear note ac"
                          style={{
                            "padding-top": "20px",
                            display: "block",
                          }}
                        >
                          1 Dotyczy uczucia ucisku w zatokach spowodowanego
                          nagromadzoną wydzieliną i obrzękiem błony śluzowej
                          nosa.
                        </div>
                      </div>
                      <span className="back resumeback">COFNIJ</span>
                    </div>
                    <span
                      className="answer "
                      data-aid="8"
                      data-qid="4"
                      data-next="6"
                    >
                      <p>Wolę miejscowe i szybkie działanie</p>
                    </span>
                  </div>
                  <div
                    className="clear note ac"
                    style={{
                      "padding-top": "20px",
                    }}
                  ></div>
                  <span className="back answerback">COFNIJ</span>
                </div>
                <div
                  className="question q_6 "
                  data-prev=""
                  data-products=".product_1,.product_2,.product_8"
                >
                  <div className="content">
                    <p>
                      Czy błona śluzowa Twojego nosa jest nadmiernie wysuszona i
                      potrzebuje nawilżenia?
                    </p>
                  </div>
                  <div className="nawig">
                    <span
                      className="answer "
                      data-aid="9"
                      data-qid="6"
                      data-next="8"
                    >
                      <p>Tak</p>
                    </span>
                    <div className="resume resume_9">
                      <p className="name">Wynik</p>
                      <div className="wysiwyg">
                        <p className="p1">
                          <span className="s1">
                            Preparat w formie aerozolu. Szybko odblokowuje nos i
                            zatoki. Jako jedyny posiada aż trzy substancje o
                            działaniu nawilżającym<sup>1</sup>.
                          </span>
                        </p>
                        <p>
                          <br />
                          <img
                            src="/web/kcfinder/uploads/images/xylosprayha.png"
                            style={{
                              width: "110px",
                              height: "263px",
                            }}
                          />
                          <br />
                          <span
                            style={{
                              color: "#171b73",
                              "font-weight": "900",
                            }}
                          >
                            SUDAFED<sup>®</sup>
                            <br />
                            XYLOSPRAY HA{" "}
                          </span>
                          <br />
                          <br />
                          <a
                            className="more"
                            href="/produkty/sudafed_xylospray_ha/"
                          >
                            WIĘCEJ
                          </a>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                          <span
                            style={{
                              "font-size": "9px",
                              "line-height": "normal",
                              display: "block",
                            }}
                          >
                            1 Wśród produktów zawierających
                            Xylometazoline/Oxymetazoline w postaci aerozolu do
                            nosa, dopuszczonych do obrotu w Polsce według
                            Urzędowy Wykaz Produktów Leczniczych Dopuszczonych
                            do Obrotu na terytorium Rzeczypospolitej Polskiej,
                            według stanu na dzień 1 Lutego 2023 r.
                          </span>
                        </p>
                      </div>
                      <span className="back resumeback">COFNIJ</span>
                    </div>
                    <span
                      className="answer "
                      data-aid="10"
                      data-qid="6"
                      data-next=""
                    >
                      <p>Nie</p>
                    </span>
                    <div className="resume resume_10">
                      <p className="name">Wynik</p>
                      <div className="wysiwyg">
                        <p className="p1">
                          <span className="s1">
                            Preparat w formie aerozolu. Szybko odblokowuje nos i
                            zatoki oraz ułatwia usunięcie zalegającej
                            wydzieliny.
                          </span>
                        </p>
                        <p>
                          <img
                            src="/web/kcfinder/uploads/images/xylospray.png"
                            style={{
                              width: "110px",
                              height: "263px",
                            }}
                          />
                          <br />
                          <span
                            style={{
                              color: "#171b73",
                              "font-weight": "900",
                            }}
                          >
                            SUDAFED<sup>®</sup>
                            <br />
                            XYLOSPRAY
                          </span>
                          <br />
                          <br />
                          <a
                            className="more"
                            href="/produkty/sudafed_xylospray/"
                          >
                            WIĘCEJ
                          </a>
                        </p>
                      </div>
                      <span className="back resumeback">COFNIJ</span>
                    </div>
                  </div>
                  <div
                    className="clear note ac"
                    style={{
                      "padding-top": "20px",
                    }}
                  ></div>
                  <span className="back answerback">COFNIJ</span>
                </div>
                <div
                  className="question q_7 "
                  data-prev=""
                  data-products=".product_3,.product_7,.product_9"
                >
                  <div className="content">
                    <p>
                      Czy błona śluzowa nosa Twojego dziecka jest nadmiernie
                      wysuszona i potrzebuje nawilżenia?
                    </p>
                  </div>
                  <div className="nawig">
                    <span
                      className="answer "
                      data-aid="15"
                      data-qid="7"
                      data-next="9"
                    >
                      <p>Tak</p>
                    </span>
                    <div className="resume resume_15">
                      <p className="name">Wynik</p>
                      <div className="wysiwyg">
                        <p className="p1">
                          Lek na katar dla Twojego dziecka. Szybko i delikatnie
                          odblokowuje nosek, a dodatkowo, dzięki zawartości
                          kwasu hialuronowego<sup>1</sup>, nawilża wysuszoną
                          podczas kataru błonę śluzową noska.
                        </p>
                        <p>
                          <br />
                          <img
                            src="/web/kcfinder/uploads/images/packshot_bez_tla.png"
                            style={{
                              width: "110px",
                              height: "261px",
                            }}
                          />
                          <br />
                          <span
                            style={{
                              color: "#171b73",
                              "font-weight": "900",
                            }}
                          >
                            SUDAFED<sup>®</sup> XYLOSPRAY HA
                            <br />
                            DLA DZIECI
                          </span>
                          <br />
                          <br />
                          <a
                            className="more"
                            href="/produkty/sudafed_xylospray_ha_dla_dzieci/"
                          >
                            WIĘCEJ
                          </a>
                        </p>
                        <p>&nbsp;</p>
                        <p
                          style={{
                            "-webkit-text-align": "left",
                            "text-align": "left",
                            "font-size": "11px",
                          }}
                        >
                          1 W postaci soli sodowej
                        </p>
                      </div>
                      <span className="back resumeback">COFNIJ</span>
                    </div>
                    <span
                      className="answer "
                      data-aid="16"
                      data-qid="7"
                      data-next=""
                    >
                      <p>Nie</p>
                    </span>
                    <div className="resume resume_16">
                      <p className="name">Wynik</p>
                      <div className="wysiwyg">
                        <p className="p1">
                          <span className="s1">
                            Lek na katar dla Twojego dziecka. Szybko i
                            delikatnie odblokowuje nos i zatoki oraz działa aż
                            do 10 godzin.
                          </span>
                        </p>
                        <p>
                          <br />
                          <img
                            src="/web/kcfinder/uploads/images/dla_dzieci.png"
                            style={{
                              width: "110px",
                              height: "263px",
                            }}
                          />
                          <br />
                          <span
                            style={{
                              color: "#171b73",
                              "font-weight": "900",
                            }}
                          >
                            SUDAFED<sup>®</sup> XYLOSPRAY
                            <br />
                            DLA DZIECI
                          </span>
                          <br />
                          <br />
                          <a
                            className="more"
                            href="/produkty/sudafed_xylospray_dla_dzieci/"
                          >
                            WIĘCEJ
                          </a>
                        </p>
                      </div>
                      <span className="back resumeback">COFNIJ</span>
                    </div>
                  </div>
                  <div
                    className="clear note ac"
                    style={{
                      "padding-top": "20px",
                    }}
                  ></div>
                  <span className="back answerback">COFNIJ</span>
                </div>
                <div
                  className="question q_8 "
                  data-prev=""
                  data-products=".product_2,.product_8"
                >
                  <div className="content">
                    <p>Czy czujesz, że masz podrażnioną błonę śluzową nosa?</p>
                  </div>
                  <div className="nawig">
                    <span
                      className="answer "
                      data-aid="17"
                      data-qid="8"
                      data-next=""
                    >
                      <p>Nie</p>
                    </span>
                    <div className="resume resume_17">
                      <p className="name">Wynik</p>
                      <div className="wysiwyg">
                        <p className="p1">
                          <span className="s1">
                            Preparat w formie aerozolu. Szybko odblokowuje nos i
                            zatoki. Jako jedyny posiada aż trzy substancje o
                            działaniu nawilżającym<sup>1</sup>.
                          </span>
                        </p>
                        <p>
                          <br />
                          <img
                            src="/web/kcfinder/uploads/images/xylosprayha.png"
                            style={{
                              width: "110px",
                              height: "263px",
                            }}
                          />
                          <br />
                          <span
                            style={{
                              color: "#171b73",
                              "font-weight": "900",
                            }}
                          >
                            SUDAFED<sup>®</sup>
                            <br />
                            XYLOSPRAY HA{" "}
                          </span>
                          <br />
                          <br />
                          <a
                            className="more"
                            href="/produkty/sudafed_xylospray_ha/"
                          >
                            WIĘCEJ
                          </a>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                          <span
                            style={{
                              "font-size": "9px",
                              "line-height": "normal",
                              display: "block",
                            }}
                          >
                            1 Wśród produktów zawierających
                            Xylometazoline/Oxymetazoline w postaci aerozolu do
                            nosa, dopuszczonych do obrotu w Polsce według
                            Urzędowy Wykaz Produktów Leczniczych Dopuszczonych
                            do Obrotu na terytorium Rzeczypospolitej Polskiej,
                            według stanu na dzień 1 Lutego 2023 r.
                          </span>
                        </p>
                      </div>
                      <span className="back resumeback">COFNIJ</span>
                    </div>
                    <span
                      className="answer "
                      data-aid="18"
                      data-qid="8"
                      data-next=""
                    >
                      <p>Tak</p>
                    </span>
                    <div className="resume resume_18">
                      <p className="name">Wynik</p>
                      <div className="wysiwyg">
                        <p className="p1">
                          Odblokowuje nos, przyspiesza gojenie ran i chroni
                          podrażnioną błonę śluzową nosa
                        </p>
                        <p>
                          <br />
                          <img
                            src="/web/kcfinder/uploads/images/Sudafed_DEX_kartonik%2Bbutelka%20.png"
                            style={{
                              width: "110px",
                              height: "224px",
                            }}
                          />
                          <br />
                          <span
                            style={{
                              color: "#171b73",
                              "font-weight": "900",
                            }}
                          >
                            SUDAFED<sup>®</sup>
                            <br />
                            XYLOSPRAY DEX&nbsp;
                          </span>
                          <br />
                          <br />
                          <a
                            className="more"
                            href="/produkty/sudafed_xylospray_dex/"
                          >
                            WIĘCEJ
                          </a>
                        </p>
                        <p>&nbsp;</p>
                      </div>
                      <span className="back resumeback">COFNIJ</span>
                    </div>
                  </div>
                  <div
                    className="clear note ac"
                    style={{
                      "padding-top": "20px",
                    }}
                  ></div>
                  <span className="back answerback">COFNIJ</span>
                </div>
                <div
                  className="question q_9 "
                  data-prev=""
                  data-products=".product_7,.product_9"
                >
                  <div className="content">
                    <p>Czy Twoje dziecko czuje, że ma podrażniony nosek?</p>
                  </div>
                  <div className="nawig">
                    <span
                      className="answer "
                      data-aid="19"
                      data-qid="9"
                      data-next=""
                    >
                      <p>Nie</p>
                    </span>
                    <div className="resume resume_19">
                      <p className="name">Wynik</p>
                      <div className="wysiwyg">
                        <p className="p1">
                          Lek na katar dla Twojego dziecka. Szybko i delikatnie
                          odblokowuje nosek, a dodatkowo, dzięki zawartości
                          kwasu hialuronowego<sup>1</sup>, nawilża wysuszoną
                          podczas kataru błonę śluzową noska.
                        </p>
                        <p>
                          <br />
                          <img
                            src="/web/kcfinder/uploads/images/packshot_bez_tla.png"
                            style={{
                              width: "110px",
                              height: "261px",
                            }}
                          />
                          <br />
                          <span
                            style={{
                              color: "#171b73",
                              "font-weight": "900",
                            }}
                          >
                            SUDAFED<sup>®</sup> XYLOSPRAY HA
                            <br />
                            DLA DZIECI
                          </span>
                          <br />
                          <br />
                          <a
                            className="more"
                            href="/produkty/sudafed_xylospray_ha_dla_dzieci/"
                          >
                            WIĘCEJ
                          </a>
                        </p>
                        <p>&nbsp;</p>
                        <p
                          style={{
                            "-webkit-text-align": "left",
                            "text-align": "left",
                            "font-size": "11px",
                          }}
                        >
                          1 W postaci soli sodowej
                        </p>
                      </div>
                      <span className="back resumeback">COFNIJ</span>
                    </div>
                    <span
                      className="answer "
                      data-aid="20"
                      data-qid="9"
                      data-next=""
                    >
                      <p>Tak</p>
                    </span>
                    <div className="resume resume_20">
                      <p className="name">Wynik</p>
                      <div className="wysiwyg">
                        <p className="p1">
                          Szybko i delikatnie odblokowuje i pomaga w gojeniu ran
                          w małych noskach.
                        </p>
                        <p>
                          <br />
                          <img
                            src="/web/kcfinder/uploads/images/Sudafed_DEX_Kids_kartonik%2Bbutelka%20.png"
                            style={{
                              width: "110px",
                              height: "224px",
                            }}
                          />
                          <br />
                          <span
                            style={{
                              color: "#171b73",
                              "font-weight": "900",
                            }}
                          >
                            SUDAFED<sup>®</sup> XYLOSPRAY DEX
                            <br />
                            DLA DZIECI
                          </span>
                          <br />
                          <br />
                          <a
                            className="more"
                            href="/produkty/sudafed_xylospray_dex_kids/"
                          >
                            WIĘCEJ
                          </a>
                        </p>
                        <p>&nbsp;</p>
                      </div>
                      <span className="back resumeback">COFNIJ</span>
                    </div>
                  </div>
                  <div
                    className="clear note ac"
                    style={{
                      "padding-top": "20px",
                    }}
                  ></div>
                  <span className="back answerback">COFNIJ</span>
                </div>
                <div
                  className="question q_5 "
                  data-prev=""
                  data-products=".product_4,.product_5,.product_6"
                >
                  <div className="content">
                    <p>Jakie inne dolegliwości rozpoznajesz?</p>
                  </div>
                  <div className="nawig">
                    <span
                      className="answer "
                      data-aid="11"
                      data-qid="5"
                      data-next=""
                    >
                      <p>
                        Uczucie ucisku w głowie i zatokach<sup>1</sup>
                      </p>
                    </span>
                    <div className="resume resume_11">
                      <p className="name">Wynik</p>
                      <div className="wysiwyg">
                        <p className="p1">
                          <span className="s1">
                            Lek w formie tabletek, odblokowuje nos i zatoki
                            ułatwiając oddychanie. Zmniejsza ilość zalegającej w
                            nosie i zatokach wydzieliny oraz ułatwia jej
                            usunięcie eliminując uczucie ucisku w głowie i
                            zatokach<sup>1</sup>.
                          </span>
                        </p>
                        <p>
                          <br />
                          <img
                            src="/web/kcfinder/uploads/images/tabletki_02.png"
                            style={{
                              width: "200px",
                              height: "168px",
                            }}
                          />
                          <br />
                          <span
                            style={{
                              color: "#171b73",
                              "font-weight": "900",
                            }}
                          >
                            SUDAFED<sup>®</sup> TABLETKI
                          </span>
                          <br />
                          <br />
                          <a
                            className="more"
                            href="/produkty/sudafed_tabletki/"
                          >
                            WIĘCEJ
                          </a>
                        </p>
                        <div
                          className="clear note ac"
                          style={{
                            "padding-top": "20px",
                            display: "block",
                          }}
                        >
                          1 Dotyczy uczucia ucisku w zatokach spowodowanego
                          nagromadzoną wydzieliną i obrzękiem błony śluzowej
                          nosa.
                        </div>
                      </div>
                      <span className="back resumeback">COFNIJ</span>
                    </div>
                    <span
                      className="answer "
                      data-aid="13"
                      data-qid="5"
                      data-next=""
                    >
                      <p>Kaszel</p>
                    </span>
                    <div className="resume resume_13">
                      <p className="name">Wynik</p>
                      <div className="wysiwyg">
                        <p className="p1">
                          <span className="s1">
                            Lek w formie syropu o przyjemnym jeżynowym smaku.
                            Działa potrójnie: odblokowuje nos, zmniejsza ilość
                            wydzieliny oraz łagodzi suchy kaszel.
                          </span>
                        </p>
                        <p>
                          <img
                            src="/web/kcfinder/uploads/images/actifed.png"
                            style={{
                              width: "177px",
                              height: "239px",
                            }}
                          />
                          <br />
                          <span
                            style={{
                              color: "#cb0073",
                              "font-weight": "900",
                            }}
                          >
                            ACTIFED<sup>®</sup> SYROP
                            <br />
                            NA KATAR I KASZEL{" "}
                          </span>
                          <br />
                          <br />
                          <a
                            className="more"
                            href="/produkty/actifed_syrop_na_katar_i_kaszel/"
                          >
                            WIĘCEJ
                          </a>
                        </p>
                      </div>
                      <span className="back resumeback">COFNIJ</span>
                    </div>
                  </div>
                  <div
                    className="clear note ac"
                    style={{
                      "padding-top": "20px",
                    }}
                  >
                    1 Dotyczy uczucia ucisku w zatokach spowodowanego
                    nagromadzoną wydzieliną i obrzękiem błony śluzowej nosa{" "}
                  </div>
                  <span className="back answerback">COFNIJ</span>
                </div>
              </div>
            </div>
            
          </div>
          <div className="choice_product">
            <div className="col product_8">
              <div className="tab">
                <div className="img">
                  <img src="/web/kcfinder/uploads/files/produkty/Sudafed_DEX_kartonik%2Bbutelka%20.png" />
                </div>
              </div>
              <p className="name">
                SUDAFED<sup>®</sup> XYLOSPRAY DEX
              </p>
            </div>
            <div className="col product_9">
              <div className="tab">
                <div className="img">
                  <img src="/web/kcfinder/uploads/files/produkty/Sudafed_DEX_Kids_kartonik%2Bbutelka%20.png" />
                </div>
              </div>
              <p className="name">
                SUDAFED<sup>®</sup> XYLOSPRAY DEX DLA DZIECI
              </p>
            </div>
            <div className="col product_2">
              <div className="tab">
                <div className="img">
                  <img src="/web/kcfinder/uploads/files/produkty/xylosprayha.png" />
                </div>
              </div>
              <p className="name">
                SUDAFED<sup>®</sup> <br />
                XYLOSPRAY HA
              </p>
            </div>
            <div className="col product_7">
              <div className="tab">
                <div className="img">
                  <img src="/web/kcfinder/uploads/files/produkty/packshot_bez_tla.png" />
                </div>
              </div>
              <p className="name">
                SUDAFED<sup>®</sup> <br />
                XYLOSPRAY HA DLA DZIECI
              </p>
            </div>
            <div className="col product_1">
              <div className="tab">
                <div className="img">
                  <img src="/web/kcfinder/uploads/files/produkty/xylospray.png" />
                </div>
              </div>
              <p className="name">
                SUDAFED<sup>®</sup> <br />
                XYLOSPRAY
              </p>
            </div>
            <div className="col product_3">
              <div className="tab">
                <div className="img">
                  <img src="/web/kcfinder/uploads/files/produkty/dla_dzieci.png" />
                </div>
              </div>
              <p className="name">
                SUDAFED<sup>®</sup> <br />
                XYLOSPRAY DLA DZIECI
              </p>
            </div>
            <div className="col product_4">
              <div className="tab">
                <div className="img">
                  <img src="/web/kcfinder/uploads/files/produkty/actifed.png" />
                </div>
              </div>
              <p className="name">
                <span
                  style={{
                    color: "#cb0073",
                  }}
                >
                  ACTIFED<sup>®</sup> SYROP
                  <br /> NA KATAR I KASZEL
                </span>
              </p>
            </div>
            <div className="col product_5">
              <div className="tab">
                <div className="img">
                  <img src="/web/kcfinder/uploads/files/produkty/tabletki_02.png" />
                </div>
              </div>
              <p className="name">
                SUDAFED<sup>®</sup> <br />
                TABLETKI
              </p>
            </div>
          </div>
        </div>
        <footer>
          <div className="page_width">
            <div className="drug_alert"></div>
            <p
              style={{
                "font-size": "10px",
                "-webkit-text-align": "right",
                "text-align": "right",
                padding: "0 0 10px",
              }}
            >
              MAT/6769/03/2019
            </p>
          </div>
          <div className="footer_content">
            <div className="page_width">
              <ul id="footer_menu">
                <li>
                  <a href="/polityka-prywatnosci/">Polityka prywatności</a>
                </li>
                <li>
                  <a href="/polityka-cookies/">Polityka cookies</a>
                </li>
                <li>
                  <a href="/nota-prawna/">Nota prawna</a>
                </li>
                <li>
                  <a href="/mapa-strony/">Mapa strony</a>
                </li>
                <li>
                  <a href="/slowniczek/">Słowniczek</a>
                </li>
                <li>
                  <a
                    id="ot-sdk-btn"
                    class="ot-sdk-show-settings"
                    rel="nofollow"
                  >
                    {" "}
                    Ustawienia plików Cookie{" "}
                  </a>
                </li>
              </ul>
              <div className="rel footer_info">
                <br />
                Podmiot odpowiedzialny: McNeil Products Limited <br />© JNTL Consumer Health (Poland) sp. z o.o. 2023<br />
                JNTL Consumer Health (Poland) sp. z o.o. w ramach swojej działalności gospodarczej prowadzi hurtowy obrót produktami leczniczymi. Jeżeli jesteś przedsiębiorcą prowadzącym aptekę ogólnodostępną i jesteś zainteresowany nabyciem produktów leczniczych oferowanych przez nas, uprzejma prośba o kontakt z działem Customer Service email: <a href="mailto:apteki@kenvue.com">apteki@kenvue.com</a>. Minimalna wartość zamówienia 500 PLN brutto. Zamówienia realizowane będą wyłącznie po otrzymaniu pełnej przedpłaty.
              </div>
            </div>
          </div>
        </footer>{" "}
      </div>
    );
  }
}

export default Page;
